.dashboard {
  background-color: white;
  color: black;
  padding-bottom: 10vh;

    display: grid;
    justify-content: center;
  grid-auto-flow: row;
  grid-gap: 64px;

  button {
    all: revert;
  }

    input {
      all: revert;
    }

  input, label {
    display:block;
    width: 50%;
  }

}

.Listing {

  .name {
    overflow: scroll;
  }

  .form {
    display: grid;
    grid-auto-flow: column;
    align-items: flex-end;
    grid-gap: 16px;
  }

  button {
    justify-self: flex-end;
  }

  input, label {
    width: 64px;
  }
}
